<template>
  <div id="add-hotel" v-if="canAccess(['store-offer' , 'update-offer'])">
    <div class="container-fluid">
      <b-form class="add-form">
        <b-row>
          <b-col sm="12" lg="4">
            <iq-card>
              <template v-slot:body>
                <div
                  class="img-preview d-flex justify-content-center align-items-center"
                >
                  <img
                    class="w-100 h-100"
                    v-if="imgPreview"
                    :src="imgPreview"
                    alt=""
                  />
                  <span v-else>{{ $t('img-preview') }}</span>
                </div>
                <div class="feild pt-3">
                  <b-form-file
                    type="text"
                    @change="getImg($event)"
                    :placeholder="$t('upload-img')"
                    required
                  ></b-form-file>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="12" lg="8">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('sidebar.offers') }}</h4>
              </template>
              <template v-slot:body>
                <b-form class="d-grid grid-cols-2 gap-5">
                  <b-row>
                    <b-col sm="12" md="6">
                        <div class="feild">
                            <label for="">{{ $t('governorate')}}</label>
                            <v-select v-model="payload.governorate_id" :options="governorates.data" :reduce="(option)=>option.id" :label="`${$i18n.locale}_name`" />
                        </div>
                    </b-col>
                    <b-col sm="12" md="6">
                      <div class="feild">
                        <label class="font-weight-bold" for=""
                          >{{ $t('percentage')}} </label
                        >
                        <b-form-input
                          type="text"
                          class="mb-2"
                          v-model="payload.percentage"
                          required
                        ></b-form-input>
                      </div>
                    </b-col>
                    <b-col sm="12" md="6">
                      <div class="feild">
                        <label class="font-weight-bold" for=""
                          >{{ $t('start-date')}}</label
                        >
                        <b-form-input
                          type="date"
                          class="mb-2"
                          v-model="payload.start_date"
                          required
                        ></b-form-input>
                      </div>
                    </b-col>
                    <b-col sm="12" md="6">
                      <div class="feild">
                        <div class="feild">
                          <label class="font-weight-bold" for=""
                            >{{ $t('end-date')}}</label
                          >
                          <b-form-input
                            type="date"
                            class="mb-2"
                            v-model="payload.end_date"
                            required
                          ></b-form-input>
                        </div>
                      </div>
                    </b-col>
                    <b-col sm="12" md="6">
                      <div class="feild">
                        <label class="font-weight-bold" for="">{{$t('arabic-language')}}</label>
                        <b-form-input
                          type="text"
                          class="mb-2"
                          :placeholder="$t('title')"
                          v-model="payload.ar_title"
                          required
                        ></b-form-input>
                        <b-form-textarea
                          type="text"
                          rows="4"
                          :placeholder="$t('desx')"
                          v-model="payload.ar_description"
                          required
                        ></b-form-textarea>
                      </div>
                    </b-col>
                    <b-col sm="12" md="6">
                      <div class="feild">
                        <label class="font-weight-bold" for="">{{$t('english-language')}}</label>
                        <b-form-input
                          type="text"
                          class="mb-2"
                          :placeholder="$t('title')"
                          v-model="payload.en_title"
                          required
                        ></b-form-input>
                        <b-form-textarea
                          type="text"
                          rows="4"
                          :placeholder="$t('desx')"
                          v-model="payload.en_description"
                          required
                        ></b-form-textarea>
                      </div>
                    </b-col>
                    <b-col sm="12" md="6">
                      <div class="feild">
                        <label class="font-weight-bold" for="">{{$t('kurdish-language')}}</label>
                        <b-form-input
                          type="text"
                          class="mb-2"
                          :placeholder="$t('title')"
                          v-model="payload.kr_title"
                          required
                        ></b-form-input>
                        <b-form-textarea
                          type="text"
                          rows="4"
                          :placeholder="$t('desx')"
                          v-model="payload.kr_description"
                          required
                        ></b-form-textarea>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
                <div class="form-controll d-flex justify-content-end py-3">
                  <b-button variant="primary" @click="submit" size="lg"
                    >{{ $t('save') }}</b-button
                  >
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
import { HotelType } from '@/enums'
export default {
  name: 'hotels',
  data () {
    return {
      show_land_img: false,
      land_img_path: '',
      imgPreview: '',
      payload: {
        copon_code: '',
        image: '',
        ar_title: '',
        en_title: '',
        kr_title: '',
        ar_description: '',
        en_description: '',
        kr_description: '',
        start_date: '',
        end_date: '',
        percentage: '',
        governorate_id: ''
      }
    }
  },
  computed: {
    HotelType () {
      return HotelType
    }
  },

  methods: {
    getImg (event) {
      this.imgPreview = URL.createObjectURL(event.target.files[0])
      this.payload.image = event.target.files[0]
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.$route.params.id) {
        this.addOffers(this.returnData(this.payload)).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$router.push('/offers')
        })
      } else {
        if (this.payload.image === '') {
          delete this.payload.image
        }
        this.updateOffers({
          id: this.$route.params.id,
          payload: this.returnData(this.payload)
        }).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$router.push('/offers')
        })
      }
    }
  },
  mounted () {
      this.authUserPermissions();
      this.getGovernorates({
      take: 100,
      page: 1,
      ar_name: '',
      en_name: '',
      kr_name: ''
    })
    if (this.$route.params.id) {
      this.getOffer(this.$route.params.id).then(() => {
        this.imgPreview = this.offer.image_path
        this.payload._method = 'PUT'
        this.payload.ar_title = this.offer.ar_title
        this.payload.en_title = this.offer.en_title
        this.payload.kr_title = this.offer.kr_title
        this.payload.ar_description = this.offer.ar_description
        this.payload.en_description = this.offer.en_description
        this.payload.kr_description = this.offer.kr_description
        this.payload.start_date = this.offer.start_date
        this.payload.end_date = this.offer.end_date
        this.payload.percentage = this.offer.percentage
        this.payload.governorate_id = this.offer.governorate_id
      })
    }
  }
}
</script>
<style>
.add-new-img {
  font-size: 18px;
  font-weight: bold;
  color: #ccc;
  height: 200px;
  border: 2px dashed #ccc;
  cursor: pointer;
}
.img-preview {
  height: 200px;
  border: 2px dashed #ccc;
}
.img-preview img {
  object-fit: contain;
}

.show-img {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

.add-form .feild input,
.add-form .feild textarea {
  background-color: #f7f7f7;
}
</style>
